import React, {useState} from 'react';
import {connect} from 'react-redux'
import * as actions from '../../store/actions'
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Switch,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  title_no_margin: {
    marginTop: theme.spacing(0)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  list: {
    paddingLeft: theme.spacing(3)
  },
  url_toggle: {
    position: 'relative',
    top: '9px'
  }
}));

const Developers  = (props) => {
  const { account_merchant_subscription_enabled } = props;

  const [showGetMerchant, setShowGetMerchant] = useState(false)
  const [showPostMerchant, setShowPostMerchant] = useState(false)
  const [showPostNote, setShowPostNote] = useState(false)
  const [showGetMerchantWithAdditionalData, setShowGetMerchantWithAdditionalData] = useState(false)
  const [showPostMerchantWithAdditionalData, setShowPostMerchantWithAdditionalData] = useState(false)
  const [showPostNoteWithAdditionalData, setShowPostNoteWithAdditionalData] = useState(false)
  const [showPostToken, setShowPostToken] = useState(false)
  const [showPostMerchantSubscription, setShowPostMerchantSubscription] = useState(false)
  const [showGetMerchantSubscription, setShowGetMerchantSubscription] = useState(false)
  const [showDeleteMerchantSubscription, setShowDeleteMerchantSubscription] = useState(false)
  const [showGetSearchHistory, setShowGetSearchHistory] = useState(false)
  const [showSandboxURL, setShowSandboxURL] = useState(false)
  const [apiURLRoot, setApiURLRoot] = useState('https://api.datamerch.com')
  const classes = useStyles();

  
  const onShowClick = (event, api) => {
    switch(api){
      case 'GET_MERCHANT':
        setShowGetMerchant(!showGetMerchant)
        break
      case 'POST_MERCHANT':
        setShowPostMerchant(!showPostMerchant)
        break
      case 'POST_NOTE':
        setShowPostNote(!showPostNote)
        break
      case 'GET_MERCHANT_WITH_ADDITIONAL_DATA':
        setShowGetMerchantWithAdditionalData(!showGetMerchantWithAdditionalData)
        break
      case 'POST_MERCHANT_WITH_ADDITIONAL_DATA':
        setShowPostMerchantWithAdditionalData(!showPostMerchantWithAdditionalData)
        break
      case 'POST_NOTE_WITH_ADDITIONAL_DATA':
        setShowPostNoteWithAdditionalData(!showPostNoteWithAdditionalData)
        break
      case 'POST_TOKEN':
        setShowPostToken(!showPostToken)
        break
      case 'POST_MERCHANT_SUBSCRIPTION':
        setShowPostMerchantSubscription(!showPostMerchantSubscription)
        break
      case 'GET_MERCHANT_SUBSCRIPTION':
        setShowGetMerchantSubscription(!showGetMerchantSubscription)
        break
      case 'DELETE_MERCHANT_SUBSCRIPTION':
        setShowDeleteMerchantSubscription(!showDeleteMerchantSubscription)
        break
      case 'GET_SEARCH_HISTORY':
        setShowGetSearchHistory(!showGetSearchHistory)
        break
      default:
        break
    }
  }

  const handleToggleURL = (event) => {
    setShowSandboxURL(!showSandboxURL)
    if (!showSandboxURL){
      setApiURLRoot('https://sandbox.api.datamerch.com')
    } else {
      setApiURLRoot('https://api.datamerch.com')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="DataMerch Developer's Guide"/>
          <Divider />
          <CardContent className={classes.terms}>
            <Typography
              variant="h4"
            >
              DATAMERCH API - Version 2 (Current)
            </Typography>
            <Typography
              className={classes.title}
              variant="h4"
            >
              HOW DO I GET MY API CREDENTIALS?
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              To make use of the DataMerch API, your account must be granted access to the API by a DataMerch administrator. To request access, please contact <a href="mailto:support@datamerch.com">support@datamerch.com</a>. If your account has been granted API access and you are an account administrator, you can log in to DataMerch and go to the "Account" page. The account page is accessed through the side menu displayed on the left when you are logged in. Once on the “Account” page, click “Account Members.”  From there, you can grant or revoke API access as well as access a member's API credentials. Only member's that have been granted access to the API will be given API credentials to use for Authentication.  Once API access is granted, click the “Show” button to display each member’s unique API credentials.
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              <i>NOTE: If you do not see "Account" as a menu option, you are not an account administrator. Please contact your account administrator to request API access.</i>
            </Typography>
            <br></br>
            <Typography
              className={classes.title}
              variant="h4"
            >
              SANDBOX
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              There is a sandbox environment available where you can try out all of the available API endpoints in a non-production environment.  When requesting an authentication token from the sandbox API, you need to provide your production Authentication Token and Authentication Key, but prepend "test_" in front (i.e. my production Authentication Token is "1234ABCD" so my sandbox Authentication Token is "test_1234ABCD").
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              <i>NOTE: Sandbox data is refreshed weekly.  After a sandbox refresh, the only merchant in the database will have an EIN of "01-0000001".</i>
            </Typography>
            <br></br>
            <Typography
              className={classes.title}
              variant="h4"
            >
              AUTHENTICATION
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              The DataMerch API uses token based authentication. Please see the "Authentication" endpoint below to retrieve a token.  You will use the token for all other API requests by including the token as an "Authorization" header.  <b>Tokens expire every 24 hours.</b>  API credentials (as mentioned above) are only used for the Authentication end point (see below).
            </Typography>
            <br></br>
            <Typography
              className={classes.title}
              variant="h4"
            >
              AVAILABLE API ENDPOINTS
            </Typography>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      VERB
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Resource
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography component="div">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                          >
                            Production Environment
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={showSandboxURL}
                            onChange={handleToggleURL}
                            color="primary"
                            name="showSandboxURL"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className={classes.url_toggle}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                          >
                            Sandbox Environment
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                    >
                      Details
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Divider />
                  </Grid>
                  
                  {/* Start AUTHENTICATION */}
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Authentication
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Authenticate with DataMerch
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/token
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showPostToken ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_TOKEN')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_TOKEN')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostToken ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        INPUT
                      </Typography>
                      <pre>
                        {
  `{
  "auth": {
    "authentication_token":"{AUTHENTICATION TOKEN}",
    "authentication_key":"{AUTHENTICATION KEY}"
  }
}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -d '{"auth": {"authentication_token":"[AUTHENTICATION  TOKEN]","authentication_key":"[AUTHENTICATION KEY]"}}' ${apiURLRoot}/api/v2/get_token -H 'Content-Type: application/json'`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        className={classes.title}
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `[
  {
    "token": "{TOKEN TO USE FOR FUTURE API REQUESTS}"
  }
]`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }

                  {/* End AUTHENTICATION */}

                  {/* Start GET Merchant */}
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      GET
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Search for a merchant by EIN or keywords in Legal Name.  Returns a maximum of 5 merchants.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/merchants/[9 digit EIN or Legal Name keywords]
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showGetMerchant ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showGetMerchant ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -H "Authorization: [TOKEN]" ${apiURLRoot}/api/v2/merchants/[9 digit EIN or Legal Name keywords]`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `[
  {
    "merchant": {
      "fein": "01-2345678",
      "legal_name": "ACME Company",
      "dba": "ACME",
      "address": "123 First Ave. S.",
      "street1": "",
      "street2": "",
      "city": "Los Angeles",
      "state": "CA",
      "business_phone": "123-456-7890",
      "business_startdate": "1/1/1985",
      "industry": "Combustables",
      "notes": [
        {
          "note": {
            "category": "Slow Pay",
            "note": "Merchant is a slow pay and/or default account.",
            "created_at": "2015-09-01 04:21:41 UTC",
            "added_by": "XYZ Funder"
          }
        }
      ]
    }
  }
]`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }

                  {/* End GET Merchant */}


                  {/* Start POST Merchant */}
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add a new merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/merchants
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showPostMerchant ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostMerchant ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        INPUT
                      </Typography>
                      <pre>
                        {
  `{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME" //optional,
  "street1": "123 First St." //optional,
  "street2": "Suite 100" //optional,
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890" //optional,
  "business_startdate": "1/1/1985" //optional,
  "industry": "Combustables" //optional,
  "note_category": "Slow Pay",
  "note": "Merchant is a slow pay and/or default account."
}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -d '{"fein": "01-2345678",
             "legal_name": "ACME Company",
             "dba": "ACME" //optional,
             "street1": "123 First St." //optional,
             "street2": "Suite 100" //optional,
             "city": "Los Angeles",
             "state": "CA",
             "business_phone": "123-456-7890" //optional,
             "business_startdate": "1/1/1985" //optional,
             "industry": "Combustables" //optional,
             "note_category": "Slow Pay",
             "note": "Merchant is a slow pay and/or default account."
            }' ${apiURLRoot}/api/v2/merchants -H 'Content-Type: application/json'  -H 'Authorization: [TOKEN]'`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        className={classes.title}
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
`{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME",
  "address": "123 First Ave. S.",
  "street1": "",
  "street2": "",
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890",
  "business_startdate": "1/1/1985",
  "industry": "Combustables",
  "notes": [
    {
      "note": {
        "category": "Slow Pay",
        "note": "Merchant is a slow pay and/or default account.",
        "created_at": "2015-09-01 04:21:41 UTC",
        "added_by": "XYZ Funder"
      }
    }
  ]
}`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }

                  {/* End POST Merchant */}

                  

                  {/* Start POST Note */}
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Note
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add note to existing merchant
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/notes
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showPostNote ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_NOTE')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_NOTE')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostNote ? 
                    <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                    <Typography
                      variant="h6"
                    >
                      INPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "note_category": "Slow Pay",
  "note": "Merchant is a slow pay and/or default account."
}`
                      }
                    </pre>
                    <br />
                    <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -d '{"fein": "01-2345678",
             "note_category": "Slow Pay",
             "note": "Merchant is a slow pay and/or default account."
            }' ${apiURLRoot}/api/v2/notes -H 'Content-Type: application/json'  -H 'Authorization: [TOKEN]'`
                        }
                      </pre>
                      <br />
                      <Divider />
                    <Typography
                      className={classes.title}
                      variant="h6"
                    >
                      OUTPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME",
  "address": "123 First Ave. S.",
  "street1": "",
  "street2": "",
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890",
  "business_startdate": "1/1/1985",
  "industry": "Combustables",
  "notes": [
    {
      "note": {
        "category": "Slow Pay",
        "note": "Merchant is a slow pay and/or default account.",
        "created_at": "2015-09-01 04:21:41 UTC",
        "added_by": "XYZ Funder"
      }
    }
  ]
}`
                      }
                    </pre>
                  </Grid>
                    : null
                  }

                  {/* End POST Note */}



                  { account_merchant_subscription_enabled ? 
                  <React.Fragment>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant Subscription
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add merchant subscription
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/merchant_subscriptions
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showPostMerchantSubscription ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT_SUBSCRIPTION')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT_SUBSCRIPTION')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostMerchantSubscription ? 
                    <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                    <Typography
                      variant="h6"
                    >
                      INPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678"
}`
                      }
                    </pre>
                    <br />
                    <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -d '{"fein": "01-2345678"
            }' ${apiURLRoot}/api/v2/merchant_subscriptions -H 'Content-Type: application/json'  -H 'Authorization: [TOKEN]'`
                        }
                      </pre>
                      <br />
                      <Divider />
                    <Typography
                      className={classes.title}
                      variant="h6"
                    >
                      OUTPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "created_at": "2023-09-01 04:21:41 UTC"
}`
                      }
                    </pre>
                  </Grid>
                    : null
                  }
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      GET
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant Subscription
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Find merchant subscription
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/merchant_subscriptions/[EIN]
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showGetMerchantSubscription ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT_SUBSCRIPTION')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT_SUBSCRIPTION')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showGetMerchantSubscription ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -H "Authorization: [TOKEN]" ${apiURLRoot}/api/v2/merchant_subscriptions/[EIN]`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `[
  {
    "merchant_subscription": {
      "fein": "01-2345678",
      "created_at": "2023-09-01 04:21:41 UTC"
    }
  }
]`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      GET
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant Subscription
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Delete merchant subscription
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/delete_merchant_subscription/[EIN]
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showDeleteMerchantSubscription ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'DELETE_MERCHANT_SUBSCRIPTION')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'DELETE_MERCHANT_SUBSCRIPTION')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showDeleteMerchantSubscription ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL - Production
                      </Typography>
                      <pre>
                        {
  `curl -H "Authorization: [TOKEN]" ${apiURLRoot}/api/v2/delete_merchant_subscription/[EIN]`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `
  {
    "errors": [error],
    "data": [result]
  }
`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }
                  </React.Fragment>
                   : null }



                   {/* Start GET Merchant with Additional Data */}
<Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      GET
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant with Additional Data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Search for a merchant by EIN or keywords in Legal Name.  Results include additional data in notes.  Returns a maximum of 5 merchants.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/merchants_with_additional_data/[9 digit EIN or Legal Name keywords]
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showGetMerchantWithAdditionalData ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT_WITH_ADDITIONAL_DATA')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_MERCHANT_WITH_ADDITIONAL_DATA')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showGetMerchantWithAdditionalData ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL - Production
                      </Typography>
                      <pre>
                        {
  `curl -H "Authorization: [TOKEN]" ${apiURLRoot}/api/v2/merchants_with_additional_data/[9 digit EIN or Legal Name keywords]`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `[
  {
    "merchant": {
      "fein": "01-2345678",
      "legal_name": "ACME Company",
      "dba": "ACME",
      "address": "123 First Ave. S.",
      "street1": "",
      "street2": "",
      "city": "Los Angeles",
      "state": "CA",
      "business_phone": "123-456-7890",
      "business_startdate": "1/1/1985",
      "industry": "Combustables",
      "notes": [
        {
          "note": {
            "category": "Slow Pay",
            "note": "Merchant is a slow pay and/or default account.",
            "submission_source": "broker",
            "broker_name": "Bob Broker",
            "created_at": "2015-09-01 04:21:41 UTC",
            "added_by": "XYZ Funder"
          }
        }
      ]
    }
  }
]`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }

                  {/* End GET Merchant with Additional Data */}

                  {/* Start POST Merchant with Additional Data */}
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Merchant with Additional Data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add a new merchant with additional note data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/merchants_with_additional_data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showPostMerchantWithAdditionalData ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT_WITH_ADDITIONAL_DATA')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_MERCHANT_WITH_ADDITIONAL_DATA')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostMerchantWithAdditionalData ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        INPUT
                      </Typography>
                      <pre>
                        {
  `{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME" //optional,
  "street1": "123 First St." //optional,
  "street2": "Suite 100" //optional,
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890" //optional,
  "business_startdate": "1/1/1985" //optional,
  "industry": "Combustables" //optional,
  "note_category": "Slow Pay",
  "note": "Merchant is a slow pay and/or default account.",
  "submission_source": "broker" //optional (in-house or broker),
  "broker_name": "Bok Broker" //required if submission_source=broker
}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -d '{"fein": "01-2345678",
             "legal_name": "ACME Company",
             "dba": "ACME" //optional,
             "street1": "123 First St." //optional,
             "street2": "Suite 100" //optional,
             "city": "Los Angeles",
             "state": "CA",
             "business_phone": "123-456-7890" //optional,
             "business_startdate": "1/1/1985" //optional,
             "industry": "Combustables" //optional,
             "note_category": "Slow Pay",
             "note": "Merchant is a slow pay and/or default account.",
             "submission_source": "broker" //optional (in-house or broker),
             "broker_name": "Bok Broker" //required if submission_source=broker
            }' ${apiURLRoot}/api/v2/merchants_with_additional_data -H 'Content-Type: application/json'  -H 'Authorization: [TOKEN]'`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        className={classes.title}
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
`{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME",
  "address": "123 First Ave. S.",
  "street1": "",
  "street2": "",
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890",
  "business_startdate": "1/1/1985",
  "industry": "Combustables",
  "notes": [
    {
      "note": {
        "category": "Slow Pay",
        "note": "Merchant is a slow pay and/or default account.",
        "submission_source": "broker",
        "broker_name": "Bob Broker",
        "created_at": "2015-09-01 04:21:41 UTC",
        "added_by": "XYZ Funder"
      }
    }
  ]
}`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }

                  {/* End POST Merchant with Additional Data */}


                  {/* Start POST Note with Additional Data */}


<Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      POST
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Note with Additional Data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Add note to existing merchant with additional data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/notes_with_additional_data
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showPostNoteWithAdditionalData ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_NOTE_WITH_ADDITIONAL_DATA')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'POST_NOTE_WITH_ADDITIONAL_DATA')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showPostNoteWithAdditionalData ? 
                    <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                    <Typography
                      variant="h6"
                    >
                      INPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "note_category": "Slow Pay",
  "note": "Merchant is a slow pay and/or default account.",
  "submission_source": "broker",
  "broker_name": "Bob Broker"
}`
                      }
                    </pre>
                    <br />
                    <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -d '{"fein": "01-2345678",
             "note_category": "Slow Pay",
             "note": "Merchant is a slow pay and/or default account.",
             "submission_source": "broker",
             "broker_name": "Bob Broker"
            }' ${apiURLRoot}/api/v2/notes_with_additional_data -H 'Content-Type: application/json'  -H 'Authorization: [TOKEN]'`
                        }
                      </pre>
                      <br />
                      <Divider />
                    <Typography
                      className={classes.title}
                      variant="h6"
                    >
                      OUTPUT
                    </Typography>
                    <pre>
                      {
`{
  "fein": "01-2345678",
  "legal_name": "ACME Company",
  "dba": "ACME",
  "address": "123 First Ave. S.",
  "street1": "",
  "street2": "",
  "city": "Los Angeles",
  "state": "CA",
  "business_phone": "123-456-7890",
  "business_startdate": "1/1/1985",
  "industry": "Combustables",
  "notes": [
    {
      "note": {
        "category": "Slow Pay",
        "note": "Merchant is a slow pay and/or default account.",
        "submission_source": "broker",
        "broker_name": "Bob Broker",
        "created_at": "2015-09-01 04:21:41 UTC",
        "added_by": "XYZ Funder"
      }
    }
  ]
}`
                      }
                    </pre>
                  </Grid>
                    : null
                  }

                  {/* End POST Note with Additional Data */}


                  {/* Start GET Search History */}
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      GET
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    <Typography
                      variant="body2"
                    >
                      Search History
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <Typography
                      variant="body2"
                    >
                      Get a list of the dates that an EIN was searched within the last 6 months.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                    <Typography
                      variant="body2"
                    >
                      {apiURLRoot}/api/v2/search_history/[9 digit EIN]
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    sm={1}
                    xl={1}
                    xs={1}
                  >
                    { showGetSearchHistory ? 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_SEARCH_HISTORY')}>
                        Hide
                      </Button>
                      : 
                      <Button variant="contained" color="primary" onClick={event => onShowClick(event, 'GET_SEARCH_HISTORY')}>
                        Show
                      </Button>
                    }
                  </Grid>
                  { showGetSearchHistory ? 
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                      >
                        HEADER
                      </Typography>
                      <pre>
                        {
  `Authorization: {TOKEN}`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        cURL
                      </Typography>
                      <pre>
                        {
  `curl -H "Authorization: [TOKEN]" ${apiURLRoot}/api/v2/search_history/[9 digit EIN]`
                        }
                      </pre>
                      <br />
                      <Divider />
                      <Typography
                        variant="h6"
                      >
                        OUTPUT
                      </Typography>
                      <pre>
                        {
  `[
  {
    "created_at": "2024-04-17T00:00:06.000Z"
  }
]`
                        }
                      </pre>
                      <br />
                      <Divider />
                    </Grid>
                    : null
                  }

                  {/* End GET Search History */}

                </Grid>
              </Grid>
            </Grid>
            <Typography
              className={classes.title}
              variant="h4"
            >
              NOTE CATEGORIES
            </Typography>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
              <ul className={classes.list}>
                <li>Suspicious Activity</li>
                <li>Slow Pay</li>
                <li>Split Payer</li>
                <li>Stacking History</li>
                <li>Default Account</li>
                {/* <li>Criminal History</li> */}
                <li>Covid-19 Hardship</li>
                {/* <li>IF - Client Default</li> */}
                {/* <li>IF - Vendor Slow/No Pay</li> */}
                <li>Default Satisfied</li>
                <li>Other</li>
              </ul>
            </Typography>
            { account_merchant_subscription_enabled ? 
              <React.Fragment>
                <Typography
                  className={classes.title}
                  variant="h4"
                >
                  MERCHANT SUBSCRIPTION WEBHOOKS
                </Typography>
                <br></br>
                <Typography
                  variant="h6"
                >
                  PAYLOAD
                </Typography>
                <Typography
                  className={classes.title_no_margin}
                  variant="subtitle2"
                >
                  <pre>
                {
  `
  {
    "Merchant EIN": "[EIN]",
    "Merchant Name": "[Merchant Name]",
    "Action": "Note Added",
    "Note Category": "Other",
    "Note": "This is an example of the webhook payload",
    "Merchant URL": "[URL for merchant in DataMerch]",
    "Action Performed By": "[Name of Funder who performed action]"
  }
`
                        }
                      </pre>
                </Typography>
              </React.Fragment>
            : null }
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    account_merchant_subscription_enabled: state.auth.user.account_merchant_subscription_enabled,
  }
}

export default connect(mapStateToProps)(Developers)