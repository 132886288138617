import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const blue = '#17507f';
const green = '#43a047';
const red = '#C22121';
const orange = '#fb8c00';
const yellow = '#ebc700';
const altblue = '#1e88e5';
const suspicious_activity = '#C22121';
const slow_pay = '#BD8D46';
const split_payer = '#F57336';
const stacking_history = '#7F1367';
const default_account = '#04756F';
const default_satisfied = '#04756F';
const criminal_history = '#1E1E20';
const covid_hardship = '#ebc700';
const if_client_default = '#777777';
const if_vendor_slow_pay = '#999999';
const other = '#225378';
const light_blue = colors.lightBlue[100];


export default {
  blue,
  black,
  white,
  green,
  red,
  orange,
  yellow,
  altblue,
  light_blue,
  primary: {
    contrastText: white,
    dark: colors.lightBlue[900],
    main: colors.lightBlue[900],
    light: colors.lightBlue[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red['A400'],
    light: colors.red['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  trial: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  iconLight: white[600],
  divider: colors.grey[200],
  suspicious_activity: {
    contrastText: white,
    main: suspicious_activity
  },
  slow_pay: {
    contrastText: white,
    main: slow_pay
  },
  split_payer: {
    contrastText: white,
    main: split_payer
  },
  stacking_history: {
    contrastText: white,
    main: stacking_history
  },
  default_account: {
    contrastText: white,
    main: default_account
  },
  default_satisfied: {
    contrastText: white,
    main: default_satisfied
  },
  criminal_history: {
    contrastText: white,
    main: criminal_history
  },
  covid_hardship: {
    contrastText: white,
    main: covid_hardship
  },
  if_client_default: {
    contrastText: white,
    main: if_client_default
  },
  if_vendor_slow_pay: {
    contrastText: white,
    main: if_vendor_slow_pay
  },
  other: {
    contrastText: white,
    main: other
  }
};
