import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'

import NoteInfo from '../NoteInfo'

const useStyles = makeStyles(() => ({
  root: {}
}));

const NoteForm = props => {
  const { className, merchantNote, onSubmitClick, is_dm_admin, loading, accounts, merchant_add, plan_level, ...rest } = props;
  const [id] = useState(null)
  const [merchant_id] = useState(null)
  const [category, setCategory] = useState('')
  const [note, setNote] = useState('')
  const [submission_source, setSubmissionSource] = useState('')
  const [broker_name, setBrokerName] = useState('')
  const [proxied_creator, setProxiedCreator] = useState('')
  const [show_broker_name, setShowBrokerName] = useState(false)
  const [quick_note, setQuickNote] = useState('')
  
  const classes = useStyles();

  useEffect(() => {
    if(merchantNote && merchantNote.id){
      setCategory(merchantNote.category)
      setNote(merchantNote.note)
      setSubmissionSource(merchantNote.submission_source)
      setBrokerName(merchantNote.broker_name)
      setProxiedCreator(null)
      if (merchantNote.submission_source === 'broker'){
        setShowBrokerName(true)
      } else {
        setShowBrokerName(false)
      }
    }
  },[merchantNote])

  const handleInputChange = (event, field) => {
    switch(field){
      case 'category':
        setCategory(event.target.value)
        break
      case 'note':
        setNote(event.target.value)
        break
      case 'proxied_creator':
        setProxiedCreator(event.target.value)
        break
      case 'submission_source':
        setSubmissionSource(event.target.value)
        setBrokerName(null)
        if (event.target.value === 'broker'){
          setShowBrokerName(true)
        } else {
          setShowBrokerName(false)
        }
        break
      case 'broker_name':
        setBrokerName(event.target.value)
        break
      // case 'quick_note':
      //   setNote(event.target.value)
      //   break
      default:
        return null
    }
  };

  return (
    <React.Fragment
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
      >
        <NoteInfo
          id={id}
          merchant_id={merchant_id}
          category={category}
          quick_note={quick_note}
          note={note}
          proxied_creator = {proxied_creator}
          submission_source={submission_source}
          broker_name={broker_name}
          is_dm_admin={is_dm_admin}
          loading={loading}
          accounts={accounts}
          merchant_add={merchant_add}
          onChange={(event, field) => handleInputChange(event, field)}
          plan_level={plan_level}
          show_broker_name={show_broker_name}
        />
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={(event, note) => onSubmitClick(event, {
              id: id,
              merchant_id: merchant_id,
              category: category,
              proxied_creator: proxied_creator,
              note: note,
              submission_source: submission_source,
              broker_name: broker_name
            })}
          >
            Save note
          </Button>
        </CardActions>
      </form>
    </React.Fragment>
  );
};


export default withErrorHandler(NoteForm, axios)