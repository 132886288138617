import React, {useState, useEffect} from 'react';

import withErrorHandler from '../../../hocs/withErrorHandler'
import { makeStyles, useTheme } from '@material-ui/styles';
import axios from '../../../httpClients/axios-datamerch'

import MemberSelect from '../../Account/MembersSelect'


import {
  Grid,
  TextField,
  CircularProgress,
  Backdrop
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  },
  selectContainer2: {
    padding: '0px 8px 4px 8px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '5px'
  },
  input: {
    background: theme.palette.light_blue
  }
}));

const NoteInfo = props => {
  const classes = useStyles();
  const { 
    onChange,
    category,
    quick_note,
    proxied_creator,
    note,
    is_dm_admin,
    accounts,
    loading,
    merchant_add,
    mode,
    submission_source,
    broker_name,
    plan_level,
    show_broker_name
  } = props;

  let categories = [
    {
      value: '',
      label: ''
    },
    {
      value: 'Default Account',
      label: 'Default Account'
    },
    {
      value: 'Suspicious Activity',
      label: 'Suspicious Activity'
    },
    {
      value: 'Slow Pay',
      label: 'Slow Pay'
    },
    {
      value: 'Split Payer',
      label: 'Split Payer'
    },
    {
      value: 'Stacking History',
      label: 'Stacking History'
    },
    // {
    //   value: 'Criminal History',
    //   label: 'Criminal History'
    // },
    {
      value: 'Covid-19 Hardship',
      label: 'Covid-19 Hardship'
    },
    {
      value: 'Other',
      label: 'Other'
    },
    {
      value: 'Default Satisfied',
      label: 'Default Satisfied'
    }
  ];

  if (is_dm_admin) {
    categories = [
      {
        value: '',
        label: ''
      },
      {
        value: 'Default Account',
        label: 'Default Account'
      },
      {
        value: 'Suspicious Activity',
        label: 'Suspicious Activity'
      },
      {
        value: 'Slow Pay',
        label: 'Slow Pay'
      },
      {
        value: 'Split Payer',
        label: 'Split Payer'
      },
      {
        value: 'Stacking History',
        label: 'Stacking History'
      },
      // {
      //   value: 'Criminal History',
      //   label: 'Criminal History'
      // },
      {
        value: 'Covid-19 Hardship',
        label: 'Covid-19 Hardship'
      },
      {
        value: 'Other',
        label: 'Other'
      },
      {
        value: 'Default Satisfied',
        label: 'Default Satisfied'
      },
      {
        value: 'Merchant Dispute',
        label: 'Merchant Dispute'
      }
    ];
  }

  let quick_notes = [];
  
  if (category === 'Default Satisfied') {
    quick_notes = [
      {
        value: '',
        label: ''
      },
      {
        value: 'Paid in Full',
        label: 'Paid in Full'
      },
      {
        value: 'Settled for less than originally owed',
        label: 'Settled for less than originally owed'
      },
      {
        value: 'Other',
        label: 'Other'
      }
    ];
  }

  const submission_sources = [
    {
      value: '',
      label: ''
    },
    {
      value: 'in-house',
      label: 'In-house/Retail'
    },
    {
      value: 'broker',
      label: 'Broker/ISO'
    }
  ];

  let membersSelect = null
  
  if (is_dm_admin && !merchant_add && mode==='add'){
    membersSelect = (
      <Grid
          item
          md={12}
          xs={12}
        >
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    )
    if(!loading){
      membersSelect = (
        <Grid
          item
          md={12}
          xs={12}
        >
          <MemberSelect
            accounts={accounts || []}
            proxied_creator={proxied_creator}
            label="Add on Behalf of"
            onChange={onChange}
          />
        </Grid>
      )
    }
  }
  
  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
      >
        {membersSelect}
        <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label="Category"
              margin="dense"
              name="category"
              onChange={(event) => onChange(event, 'category')}
              required
              select
              SelectProps={{ native: true }}
              value={category || ''}
              variant="outlined"
            >
              {categories.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
          >
            {category !== 'Default Satisfied' ?
              <TextField
                fullWidth
                label="Note"
                margin="dense"
                name="note"
                onChange={(event) => onChange(event, 'note')}
                required
                value={note || ''}
                variant="outlined"
              />
            : 
              <TextField
                fullWidth
                label="Quick Note Options"
                margin="dense"
                name="quick_note"
                onChange={(event) => onChange(event, 'note')}
                select
                SelectProps={{ native: true }}
                value={quick_note || ''}
                variant="outlined"
              >
                {quick_notes.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
          </Grid>
      </Grid>
      {category === 'Default Satisfied' ?
        <Grid
          container
          spacing={3}
        >
          <Grid
              item
              md={4}
              xs={12}
            >
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <TextField
                fullWidth
                label="Note"
                margin="dense"
                name="note"
                onChange={(event) => onChange(event, 'note')}
                required
                value={note || ''}
                variant="outlined"
              />
            </Grid>
        </Grid>
      : null }
      { plan_level==='PRO' || plan_level==='BASIC' ?
          <Grid
          container
          spacing={3}
        >
          <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Submission Source"
                margin="dense"
                name="submission_source"
                onChange={(event) => {onChange(event, 'submission_source')}}
                select
                SelectProps={{ native: true }}
                value={submission_source || ''}
                variant="outlined"
                InputProps={{ className: classes.input }}
              >
                {submission_sources.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              { show_broker_name ?
              <TextField
                fullWidth
                label="Broker Name"
                margin="dense"
                name="broker_name"
                onChange={(event) => onChange(event, 'broker_name')}
                value={broker_name || ''}
                variant="outlined"
                InputProps={{ className: classes.input }}
                helperText="name only, do not add comments"
                required={show_broker_name}
              />
              : null}
            </Grid>
        </Grid>
        : null
      }
    </React.Fragment>
  );
};

export default withErrorHandler(NoteInfo, axios)